import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useStoreState } from 'easy-peasy';
import { StoreProvider } from 'easy-peasy';
import { IntlProvider } from 'react-intl';

import {
  PublicRoute,
  PrivateRoute,
  store,
  queryClient,
  QueryParamsProvider,
  PermissionsProvider,
} from '@webfx/core-web';

import { NotSupportedBrowser, NotFoundPage } from '@webfx/common-web';
import { SignIn, Logout, ConfirmPassword } from '@webfx/auth-web';
import { FlagOverridesWidget } from '@webfx/web-ui-connected';

import {
  useGetScreenSize,
  useIsSupportedBrowser,
  useMeasure,
  useNotifications,
} from '@webfx/web-hooks';
import { useVisibilityRepersist } from '@webfx/web-state';
import Admin from './Admin';

const messages = require('../../lang/en.json');

function App() {
  const isSupportedBrowser = useIsSupportedBrowser();
  const { NotificationsContainer } = useNotifications();
  const lastViewed = useStoreState((state) => state.router.lastViewed);
  let redirectOnAuthorized = '/';

  if (lastViewed?.pathname && lastViewed?.pathname !== '/logout') {
    redirectOnAuthorized = lastViewed.pathname + lastViewed.search;
  }

  useVisibilityRepersist();
  useMeasure();
  useGetScreenSize(); // TODO: figure out what this is for?

  if (!isSupportedBrowser) {
    return <NotSupportedBrowser />;
  }

  return (
    <>
      <NotificationsContainer />
      <Switch>
        <PublicRoute
          exact
          path="/confirm-password"
          component={ConfirmPassword}
          redirectOnAuthorized={redirectOnAuthorized}
        />
        <PublicRoute
          exact
          path="/signin"
          component={SignIn}
          redirectOnAuthorized={redirectOnAuthorized}
        />
        <PublicRoute
          path="/feedback/auth"
          component={SignIn}
          redirectOnAuthorized="/feedback"
          passQueryString
        />
        <Route path="/not-found" component={NotFoundPage} />
        <PrivateRoute path="/logout" component={Logout} />
        <PrivateRoute path="/" component={Admin} />
      </Switch>
    </>
  );
}

/**
 * Renders the main application component with necessary providers.
 * @returns {JSX.Element} The rendered application component.
 */
export default function AppProvider() {
  return (
    <StoreProvider store={store}>
      <IntlProvider locale="en" defaultLocale="en" messages={messages}>
        <Router>
          <QueryClientProvider client={queryClient}>
            <PermissionsProvider>
              <QueryParamsProvider>
                <App />
              </QueryParamsProvider>
              <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
              <FlagOverridesWidget />
            </PermissionsProvider>
          </QueryClientProvider>
        </Router>
      </IntlProvider>
    </StoreProvider>
  );
}
